import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

import { currentDevice } from '../../core/utils/currentDevice'

export function init(ss_slider, ss_nav, callback, options = {}) {
  if (!ss_slider) return false
  const slides = ss_slider.querySelectorAll('.js-slide-element')
  const slidesObserver = new IntersectionObserver(observeSlides, options)
  for (let i = 0; i < slides.length; ++i) {
    slidesObserver.observe(slides[i])
  }

  function observeSlides(entries) {
    if (entries.length) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting && ss_nav) {
          ss_nav.querySelectorAll('.radio-btn').forEach(function (nav_btn) {
            nav_btn.classList.remove('active')
          })
          callback && callback(entry.target)
          const slide_item = ss_nav.querySelector(`[data-slide-index="${entry.target.getAttribute('data-index')}"]`)
          if (slide_item !== null) slide_item.classList.add('active')
        }
      })
    }
  }
}

export function initWithMutation(ss_slider, ss_nav, callback, options = { attributes: true }) {
  const slides = ss_slider.querySelectorAll('.js-slide-element')
  const slidesObserver = new MutationObserver(observeSlides)

  function observeSlides(mutations) {
    if (!mutations.length) return
    mutations.forEach(mutation => {
      const { target, attributeName } = mutation

      if (attributeName === 'class' && ss_nav) {
        if (!target.classList.contains('active')) return

        ss_nav.querySelectorAll('.radio-btn').forEach(function (nav_btn) {
          nav_btn.classList.remove('active')
        })
        callback && callback(target)
        ss_nav.querySelector(`[data-slide-index="${target.getAttribute('data-index')}"]`).classList.add('active')
      }
    })
  }

  for (let i = 0; i < slides.length; ++i) {
    slidesObserver.observe(slides[i], options)
  }
}

export function carouselModule(block) {
  const carouselContainer = `${block} .carousel-container`

  if (!currentDevice.isMobile) {
    Swiper.use([Navigation, Pagination])
    const swiper = new Swiper(carouselContainer, {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      lazyPreloadPrevNext: 3,
    })
  } else {
    const carouselContainerMobile = Array.from(document.querySelectorAll(carouselContainer))

    carouselContainerMobile.forEach(carousel => {
      const carouselContent = carousel.querySelectorAll('.carousel')
      const arrItems = Array.from(carousel.querySelectorAll('.carousel__item'))
      const itemsLength = arrItems.length

      // Show navigation buttons if more than one photo
      if (itemsLength > 1) {
        // We find out the width of each photo
        const itemsWidth = arrItems.map(arrItem => arrItem.scrollWidth)
        // Sum total width
        const totalItemsWidth = itemsWidth.reduce((a, b) => a + b, 0)

        const controlCarousels = carousel.querySelector('.controls-carousel')

        controlCarousels.innerHTML = ''

        arrItems.forEach((arrItem, index) => {
          // Add template
          controlCarousels.innerHTML += templateNavigationControls()
          // To the first button we add aria-press true so that it is highlighted
          if (index === 0) {
            controlCarousels.querySelector('button').setAttribute('aria-pressed', true)
          }
        })

        const buttonsNavigationCarousel = carousel.querySelectorAll('.controls__dot')

        buttonsNavigationCarousel.forEach((buttonNavigationCarousel, index) => {
          buttonNavigationCarousel.addEventListener('click', e => {
            e.preventDefault()
            e.stopPropagation()
            setAriaPressed(buttonsNavigationCarousel, index)

            const destinoCarrusel = carousel.querySelector('.carousel')

            // Calculate total width of photos for scroll scrolling
            const scrollLeft = Math.floor(totalItemsWidth * (index / itemsLength))

            smoothScroll(destinoCarrusel, scrollLeft, true)
          })
        })

        //  If we change the photo, mark the corresponding navigation button.
        carouselContent.forEach(carouselItem => {
          carouselItem.addEventListener(
            'scroll',
            () => {
              const index = Math.round((carouselItem.scrollLeft / carouselItem.scrollWidth) * itemsLength)
              setAriaPressed(buttonsNavigationCarousel, index)
            },
            200
          )
        })
      }
    })

    // Template HTML
    function templateNavigationControls() {
      return `
        <button class="controls__dot"></button>
      `
    }

    // Scroll Smooth
    function smoothScroll(node, topOrLeft, horizontal) {
      return node.scrollTo({
        [horizontal ? 'left' : 'top']: topOrLeft,
        behavior: 'smooth',
      })
    }

    // Add aria-pressed to false for every navigation item except the one in focus.
    function setAriaPressed(elements, index) {
      elements.forEach((element, i) => {
        element.setAttribute('aria-pressed', !!(i === index))
      })
    }
    IB.lazyImg.loadAllImagesSelector($('.carousel'))
  }
}
