import _debounce from 'lodash/debounce'

import * as SliderNav from '../../elements/scrollsnap_slider/scrollsnap_slider_nav'
import fakeGif from '../../utils/fakeGif'
import { loadHlsVideo } from '../../utils/load_hls_video'

const MEGABANNER_SELECTOR = '.js-megabanner-slider'
const is_mobile = IB.currentDevice === 'mobile'

const $block = $(MEGABANNER_SELECTOR)
let sliderState = {
  isPlaying: false,
  activeIndex: 0,
}

const configAutoplaySpeed = $block.data('autoplaySpeed') || 6
const mainSliderSpeed = configAutoplaySpeed * 1000
let autoplaySpeed = mainSliderSpeed

let startX = 0
let startY = 0

function initializeSliderSwipeEventHandlers(items, items_nav) {
  const slider = document.querySelector('.js-megabanner-slider .ss-slider')

  slider.addEventListener('touchstart', function (event) {
    const touch = event.touches[0]
    startX = touch.pageX
    startY = touch.pageY
  })

  slider.addEventListener(
    'touchmove',
    _debounce(function (event) {
      const touch = event.touches[0]
      const currentX = touch.pageX
      const currentY = touch.pageY
      const diffX = startX - currentX
      const diffY = startY - currentY
      const isHorizontalSwipe = Math.abs(diffX) > Math.abs(diffY)

      if (!isHorizontalSwipe) {
        return
      }

      const activeSlide = document.querySelector('.js-megabanner-slider .js-slide-element.active')
      const currentActiveSlideIndexInArray = Array.from(items).indexOf(activeSlide)
      const max_items = items.length

      let nextItemArrayIndex

      if (diffX > 0) {
        nextItemArrayIndex = currentActiveSlideIndexInArray + 1 >= max_items ? 0 : currentActiveSlideIndexInArray + 1
      } else {
        nextItemArrayIndex = currentActiveSlideIndexInArray - 1 < 0 ? max_items - 1 : currentActiveSlideIndexInArray - 1
      }

      clearTimeout(mainLoopTimeoutID) // stops autoplay
      changePicture(items, nextItemArrayIndex, items_nav) // changes slide
      mainLoop(items, items_nav, nextItemArrayIndex) // starts autoplay again
    }, 100)
  )
}

export function init(megabanner) {
  const videos = megabanner.find('.js-head-video');

  if (videos.length) loadVideos(videos);

  loadHlsVideo('.js-megabanner-slider');
  let $items = megabanner.find('.item:not(.hidden)');

  if (!$items.length) return;

  let device = IB.currentDevice;
  let position_name = device === 'small_desktop' ? 'desktop' : device;
  position_name = 'positions-' + position_name;

  let $that;
  let coords;
  let $ft_item_container;
  $items.each(function () {
    $ft_item_container = megabanner.find('.ft-item-container:not(.js-hotel-slide):not(.js-text-slide)');
    $that = megabanner.find('.ft-item');

    if (!$that.length) {
      return;
    }
    if (typeof $that.data(position_name) == 'undefined') {
      coords = 'left-middle';
    } else {
      coords = $that.data(position_name);
    }
    $ft_item_container.addClass(coords);
  });

  megabanner.data('sliderState', {
    isPlaying: false,
    activeIndex: 0,
  });
  sliderState = megabanner.data('sliderState');

  document.addEventListener('ada:activated', function () {
    activateADAalts();
  });

  document.addEventListener('ada:deactivated', function () {
    deactivateADAalts();
  });
}

// LOAD VIDEO
function loadVideos (videos) {

  if (!videos.length) return;

  videos.forEach((video) => {
    if (Boolean(video.querySelector('source'))) return; // Sources already loaded
    let videoSources = Boolean(video.dataset.mp4Src) ? `<source src="${video.dataset.mp4Src}" type="video/mp4">` : '';
    videoSources += Boolean(video.dataset.webmSrc) ? `<source src="${video.dataset.webmSrc}" type="video/webm">` : '';
    video.innerHTML += videoSources;
  });

}

//--------- AUTOPLAY --------------------------------------------------------------
let mainLoopTimeoutID;
/**
 * Recursive loop that shows a new slide in each iteration
 * @param {*} items - array of slides
 * @param {*} items_nav - nav element
 */
function mainLoop(items, items_nav, current_item) {
  const $currentSlide = $(items[current_item]);
  const $gifSlides = $currentSlide.find('.js-gif-effect');

  changePicture(items, current_item, items_nav);
  current_item = current_item + 1 >= items.length ? 0 : current_item + 1;

  if ($gifSlides.length) {
    let currentGifInstance = $currentSlide.data('fakeGif');
    currentGifInstance.start();

    autoplaySpeed = currentGifInstance.duration > autoplaySpeed ? currentGifInstance.duration : mainSliderSpeed;
  } else {
    autoplaySpeed = mainSliderSpeed;
  }

  mainLoopTimeoutID = setTimeout(() => mainLoop(items, items_nav, current_item), autoplaySpeed);
}

function startAutoplay(items, items_nav, current_item) {
  sliderState.isPlaying = true;
  mainLoop(items, items_nav, current_item);
}

function stopAutoplay($slides) {
  if (!sliderState.isPlaying) return;
  sliderState.isPlaying = false;
}

//--------- SLIDER MANAGEMENT --------------------------------------------------------------

/**
 * Adds hotel slide to megabanner
 */
function addHotelSlide(hotel_image, hotel_url, hotel_name, hotel_description, hotel_stars, hotel_segment_color, hotel_segment_text, megabanner) {
  let hotel = megabanner.find('#megabanner-item-template').get(0);

  if (hotel) {
    hotel.querySelector('[data-hotel-img]').src = hotel_image;
    hotel.querySelector('[data-hotel-url]').setAttribute('href', hotel_url);
    hotel.querySelector('[data-hotel-name]').textContent = hotel_name;
    hotel.querySelector('[data-hotel-name]').dataset.hotelName = hotel_name;
    hotel.querySelector('[data-hotel-description]').innerHTML = hotel_description;
    hotel.querySelector('[data-hotel-stars]').classList.add(`r${hotel_stars}*`);
    hotel.querySelector('[data-hotel-stars-desc]').textContent = hotel_stars;
    if (hotel_segment_color) {
      hotel.querySelector('[data-hotel-segment]').classList.add(hotel_segment_color);
    }
    if (hotel_segment_text) {
      hotel.querySelector('[data-hotel-segment]').textContent = hotel_segment_text;
    }
    hotel.classList.remove('hidden');
    hotel.classList.remove('is-hidden');

    if (IB.currentDevice !== 'mobile') {
      let $items = $block.find('.item:not(.hidden)');
      let $items_nav = $block.find('.items-nav');

      $items_nav.removeClass('is-hidden');
      stopAutoplay();
      $items_nav.find('[data-item-total-items]').html($items.length);
      $items_nav.find('[data-item-current-index]').html(2);
    } else {
      let slider = document.querySelector('.js-megabanner-slider .ss-slider');
      let $slider = $('.js-megabanner-slider .ss-slider');
      let $hotel = $('#megabanner-item-template');
      let hotel_slider_nav = document.querySelector('[data-hotel-slide-nav]');

      let slider_nav = document.querySelector('.ss-slider-nav');

      hotel.classList.add('ss-slide');
      $slider.scrollLeft($hotel.offset().left);
      if (hotel_slider_nav) {
        hotel_slider_nav.classList.remove('hidden');
        document.querySelector('.first-radio-btn')?.classList.add('is-hidden');
      }
      if (slider_nav) {
        slider_nav.classList.remove('is-hidden');
      }
      if (slider) {
        if (is_mobile) {
          SliderNav.initWithMutation(slider, slider.closest('.ss-slider-container').querySelector('.ss-slider-nav'));
        } else {
          SliderNav.init(slider, slider.closest('.ss-slider-container').querySelector('.ss-slider-nav'));
        }
      }
    }
    hotel.classList.add('active');
  }
}

/**
 * Gets last visited hotel data from localstorage to create and add an hotel slide to megabanner
 */
function addHotelSlideFromLocalStorage(item, megabanner) {
  const hotel_stars = item.category;
  const hotel_description = item.summary ? item.summary : '';
  const hotel_name = item.place_title;
  const hotel_url = item.path;
  let hotel_image = '';
  let hotel_segment_color = '';
  let hotel_segment_text = '';

  // We can not continue if ther is no hotel image
  if (IB.currentDevice === 'desktop') {
    if (!item.image_desktop) return;
    hotel_image = item.image_desktop;
  }
  if (IB.currentDevice === 'tablet') {
    if (!item.image_tablet) return;
    hotel_image = item.image_tablet;
  }
  if (IB.currentDevice === 'mobile') {
    if (!item.image_mobile) return;
    hotel_image = item.image_mobile;
  }
  if (item.segmentations.length) {
    hotel_segment_color = `seg-${item.segmentations[0].class}`;
    hotel_segment_text = item.segmentations[0].title;
  }

  addHotelSlide(hotel_image, hotel_url, hotel_name, hotel_description, hotel_stars, hotel_segment_color, hotel_segment_text, megabanner);
}

/**
 * Starts slider
 */
function startSlider(megabanner) {
  console.log('startSlider: call registered');
  megabanner ||= $('.js-megabanner-slider').first()
  if (!megabanner.length) return

  if (document.querySelector('.js-megabanner-slider') === null) return;

  console.log('startSlider: initializing...');

  megabanner.find('.corporative-banner.static-slide').addClass('is-hidden').removeClass('active');

  showFalseGif(); // shows banner offer false gif

  let local_last_searches = localStorage.getItem('last_searches');
  if (local_last_searches) {
    local_last_searches = JSON.parse(local_last_searches);
    if (local_last_searches.length === 0) {
      console.warn('startSlider: no last searches, empty object');
    } else {
      // We only want hotel searches
      local_last_searches = local_last_searches.filter(search => search.place_to_go.includes('h'));
      if (local_last_searches.length) {
        let lastHotelSearch = local_last_searches[local_last_searches.length - 1];
        addHotelSlideFromLocalStorage(lastHotelSearch, megabanner);
      } else {
        console.warn('startSlider: no hotel in last searches');
      }
    }
  } else {
    console.warn('startSlider: no last searches available');
  }

  initChangeNavButton();
  // starts autoplay
  let $items = megabanner.find('.item:not(.hidden):not(.is-hidden)');
  let $items_nav = is_mobile ? megabanner.find('.ss-slider-nav') : megabanner.find('.items-nav');
  let $currentItem = 0;

  updateSliderCounter($items, $items_nav);

  // play new promo banner
  startAutoplay($items, $items_nav, $currentItem);

  // Hide navigation buttons when necessary
  if ($items.length > 1) {
    $items_nav.removeClass('is-hidden');
  }

  initializeNavButtons($items, $items_nav);

  if (is_mobile) initializeSliderSwipeEventHandlers($items, $items_nav);

  activateADAalts(); // replace img for alts if in accessibility mode

  requestIdleCallback(function () {
    const interval = setInterval(function () {
      if (typeof utag !== 'undefined') {
        document.querySelector('.home-page .js-megabanner-slider').dispatchEvent(new CustomEvent('homeWithFastbookingAnalytics'));
        clearInterval(interval);
      }
    }, 100);
  });
}

function changePicture(items, item_index, items_nav) {
  let $item = $(items[item_index]);
  let klass = 'active';

  if ($item.hasClass('js-false-gif-slide')) {
    const $gifSlides = $item.find('.js-gif-effect');
    let currentGifInstance = $item.data('fakeGif');

    if (!currentGifInstance && $gifSlides.length) {
      $item.data('fakeGif', new fakeGif({ gifSlides: $gifSlides, maxDuration: mainSliderSpeed }));
      currentGifInstance = $item.data('fakeGif');
    }

    currentGifInstance.start();
  }

  items.each(function () {
    $(this).removeClass(klass);
  });

  $item.addClass(klass);

  if (items_nav) {
    if (items_nav.data('active-item-index')) {
      items_nav.attr('data-active-item-index', item_index + 1);
    }
    if (items_nav.find('[data-item-current-index]').length) {
      items_nav.find('[data-item-current-index]').html(item_index + 1);
    }
  }
}

/**
 * shows banner offer false gif
 */
function showFalseGif() {
  const $falseGif = $block.find('.js-false-gif-slide');

  if (!$falseGif.length) return;

  $falseGif.removeClass('is-hidden');
  if (is_mobile) {
    let false_gif_slider_nav = document.querySelector('[data-nested-items]');
    if (false_gif_slider_nav != null) false_gif_slider_nav.classList.remove('hidden');
  }
}

//--------- NAVIGATION --------------------------------------------------------------

function initChangeNavButton() {
  let slider = document.querySelector('.js-megabanner-slider .ss-slider');
  if (slider) {
    if (is_mobile) {
      SliderNav.initWithMutation(slider, slider.closest('.ss-slider-container').querySelector('.ss-slider-nav'));
    } else {
      SliderNav.init(slider, slider.closest('.ss-slider-container').querySelector('.ss-slider-nav'));
    }
  }
}

function initializeNavButtons(items, items_nav) {
  let $items_nav_btns = items_nav.find('.nav-prev, .nav-next');

  $items_nav_btns.off();
  $items_nav_btns.on('click', function (event) {
    let current_item_index = parseInt($block.find('[data-item-current-index]').html());
    let next_item_index = current_item_index;
    let max_items = parseInt($block.find('[data-item-total-items]').html());

    stopAutoplay();

    if ($(event.currentTarget).hasClass('nav-prev')) {
      next_item_index = current_item_index === 1 ? max_items : next_item_index - 1;
    } else {
      next_item_index = current_item_index === items.length ? 1 : next_item_index + 1;
    }
    changePicture(items, next_item_index - 1, items_nav);
  });
}

//--------- PAGINATION --------------------------------------------------------------

function updateSliderCounter(items, items_nav) {
  let maxItems = items.length;

  if (items_nav === undefined || items_nav.length == 0 || items_nav[0].querySelector('.counter') == null) return;

  items_nav[0].querySelector('.counter').querySelector('[data-item-current-index]').innerHTML = 0;
  items_nav[0].querySelector('.counter').querySelector('[data-item-total-items]').innerHTML = maxItems;

  changePicture(items, 0, items_nav);
}

//--------- ACCESIBILITY --------------------------------------------------------------

function activateADAalts() {
  if (IB.a11y.isAdaActivated() !== true) return;
  $block.each(function () {
    let $item = $(this).find('.item:not(.hidden)');
    let $items = $item.find('.items');
    let picture_blocks = $items.children();

    // Si estoy en modo accesible debo ocultar las imágenes que se superponen sobre la imagen principal
    // para sustituirlas por sus alts
    picture_blocks.each(function () {
      if (!$(this).hasClass('js-search-slide')) {
        const $that = $(this).find('.ft-item');
        let image_alt = $that.find('img').attr('alt');

        if (!IB.is_in_microsite && image_alt) {
          $that.parent().addClass('without-img hidden');
          $that.addClass('has-alt-text');
          $that.find('picture').addClass('hidden');
          $that.append("<div class='t-hero'>" + image_alt + '</div>');
        }
      }
    });
  });
}

function deactivateADAalts() {
  if (IB.a11y.isAdaActivated() === true) return;
  $block.each(function () {
    let $item = $(this).find('.item:not(.hidden)');
    let $items = $item.find('.items');
    let picture_blocks = $items.children();

    // Si estoy en modo accesible debo eliminar los alts de las imágenes para volver
    // a mostrar las imágenes
    picture_blocks.each(function () {
      if (!$(this).hasClass('js-search-slide')) {
        const $that = $(this).find('.ft-item');
        let image_alt = $that.find('img').attr('alt');

        if (!IB.is_in_microsite && image_alt) {
          $that.parent().removeClass('without-img hidden');
          $that.removeClass('has-alt-text');
          $that.find('picture').removeClass('hidden');
          $that.find('.t-hero').remove();
        }
      }
    });
  });
}

//--------- PUBLIC --------------------------------------------------------------

window.IB.megabanner = {
  init: init,
  startSlider: startSlider,
};

//--------- INITIALIZE --------------------------------------------------------------

$(function () {
  let megabanners = $(MEGABANNER_SELECTOR);

  if (!megabanners.length) return;

  megabanners.each(function(){
    IB.megabanner.init($(this));

    if (!$(this).find('.js-corporative-banner').length) {
      IB.megabanner.startSlider($(this));
    }
  })

  if (IB.is_in_microsite) {
    IB.megabanner.startSlider();
  }
});
