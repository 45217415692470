
/**
 * Creates a new fake gif which iterates over given gifSlides
 */
class fakeGif {
  constructor({ gifSlides, maxDuration = 0 }) {
    this.gifSlides = gifSlides;
    this.isPlaying = false;
    this.activeIndex = 0;
    this.transitionsCounter = 0;

    const gifSpeeds = this.gifSlides.get().map(gifItem => (gifItem.dataset.autoplaySpeed ? parseInt(gifItem.dataset.autoplaySpeed) : 6));
    this.totalDuration =
      gifSpeeds.reduce(function (lastValue, newValue) {
        return lastValue + newValue;
      }) * 1000;

    this.maxTransitions = maxDuration ? Math.ceil(maxDuration / this.totalDuration) : 0;
  }

  // Getters
  get duration() {
    return this.totalDuration;
  }

  // Methods
  gifLoop() {
    const currentSlide = $(this.gifSlides[this.activeIndex]);
    const currentGifAutoplaySpeed = currentSlide.data('autoplaySpeed') || 6;
    let gifAutoplaySpeed = parseInt(currentGifAutoplaySpeed) * 1000;

    this.gifSlides.removeClass('active');
    currentSlide.addClass('active');
    this.activeIndex = this.gifSlides.get(this.activeIndex + 1) ? this.activeIndex + 1 : 0;
    this.transitionsCounter += 1;

    if (this.maxTransitions && this.transitionsCounter > this.maxTransitions) {
      this.stop();
    } else {
      window.setTimeout(() => this.gifLoop(), gifAutoplaySpeed);
    }
  }

  start() {
    if (this.isPlaying) return;
    this.gifLoop();
    this.isPlaying = true;
  }

  stop() {
    if (!this.isPlaying) return;
    Object.assign(this, {
      isPlaying: false,
      activeIndex: 0,
      transitionsCounter: 0,
    });
  }
}

export default fakeGif;
